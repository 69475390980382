import classNames from 'classnames';
import { ReactComponent as CheckIcon } from '../../icons/checked.svg';
import './Checkbox.scss';
import { isValidElement } from 'react';

interface Props {
  value: boolean;
  label?: string | React.ReactNode;
  labelFirst?: boolean;
  size?: 'sm' | 'md';
  className?: string;
  markClassName?: string;
  labelClassName?: string;
  onChange: (newValue: boolean) => void;
}

const Checkbox = ({
  value = false,
  labelFirst = false,
  size = 'md',
  label,
  className = '',
  markClassName = '',
  labelClassName = '',
  onChange
}: Props) => {
  const handleClick = () => {
    onChange(!value);
  };

  return (
    <div
      className={classNames(`Checkbox ${className}`, {
        'Checkbox--small': size === 'sm',
        'Checkbox--medium': size === 'md',
        'Checkbox--labelFirst': labelFirst
      })}
    >
      <div
        className={classNames(`Checkbox__markContainer ${markClassName}`)}
        onClick={handleClick}
      >
        {value && <CheckIcon className='Checkbox__checked' />}
      </div>
      <div
        className={classNames(`Checkbox__label ${labelClassName}`)}
        onClick={isValidElement(label) ? undefined : handleClick}
      >
        {label}
      </div>
    </div>
  );
};

export default Checkbox;
