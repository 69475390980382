import classNames from 'classnames';
import { getMediaFile } from '../../core/api';
import './Section.scss';

interface IProps {
  title?: string;
  icon?: string | React.ReactNode;
  information?: string;
  actionText?: string;
  action?: () => void;
  children?: React.ReactNode;
  className?: string;
  showHeaderLine?: boolean | undefined;
}
const Section = ({
  title,
  icon,
  information,
  actionText = 'see all',
  action,
  children,
  className = '',
  showHeaderLine
}: IProps) => {
  return (
    <section
      className={classNames(`Section ${className}`, {
        'Section__header--bottom-line': showHeaderLine
      })}
    >
      <div className={'Section__header'}>
        {icon && typeof icon === 'string' ? (
          <img
            className='Section__icon'
            src={getMediaFile(icon)}
            alt='Section Icon'
          />
        ) : (
          icon
        )}
        {title && (
          <h3
            className={classNames(`Section__title`, {
              'Section__title--spaced': !!icon
            })}
          >
            {title}
          </h3>
        )}
        {information && (
          <span className='Section__information'>{information}</span>
        )}
        {action && (
          <button className='Section__action' onClick={action}>
            {actionText}
          </button>
        )}
      </div>
      {children}
    </section>
  );
};

export default Section;
