import { PropsWithChildren } from 'react';
import cn from 'classnames';
import './Button.scss';

interface Props {
  onClick?: () => void;
  className?: string;
  hint?: string;
  variant?: 'solid' | 'outline' | 'action' | 'icon' | 'text';
  color?: 'light' | 'primary' | 'primary-light' | 'secondary' | 'danger';
  type?: 'button' | 'submit' | 'reset';
  borderRadius?: 'rounded' | 'pill';
  disabled?: boolean;
  size?: 'auto' | 'sm' | 'md' | 'lg' | 'full-width';
  icon?: React.ReactNode;
}

const Button = ({
  onClick,
  hint,
  children,
  className = '',
  type = 'button',
  disabled = false,
  size = 'md',
  variant = 'solid',
  borderRadius = 'rounded',
  color = 'primary',
  icon
}: PropsWithChildren<Props>) => {
  return (
    <button
      className={cn(`Button ${className}`, {
        'Button--withIconAndLabel': icon && children,
        'Button--auto': size === 'auto',
        'Button--sm': size === 'sm',
        'Button--md': size === 'md',
        'Button--lg': size === 'lg',
        'Button--fullWidth': size === 'full-width',
        'Button--solid': variant === 'solid',
        'Button--outline': variant === 'outline',
        'Button--action': variant === 'action',
        'Button--icon': variant === 'icon',
        'Button--text': variant === 'text',
        'Button--light': color === 'light',
        'Button--primary': color === 'primary' && variant !== 'icon',
        'Button--primaryLight': color == 'primary-light',
        'Button--secondary': color === 'secondary',
        'Button--danger': color === 'danger',
        'Button--pill': borderRadius === 'pill',
        'Button--rounded': borderRadius === 'rounded'
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      title={hint}
    >
      {icon && <div className='Button__icon'>{icon}</div>}
      {children && (
        <div
          className={cn('Button__label', {
            'Button__label--icon': !!icon
          })}
        >
          {children}
        </div>
      )}
    </button>
  );
};

export default Button;
