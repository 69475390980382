import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import GigGallery from '../../components/GigGallery/GigGallery';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import { useSearch, useStores } from '../../hooks';
import './Search.scss';
import { masterSearch } from '../../core/api';
import MixpanelTracking from '../../core/services/MixpanelTracking';
import { TrackingEvents } from '../../core/enums';
import SearchInput from '../../components/SearchInput/SearchInput';
import VideoGallery from '../../components/VideoGallery/VideoGallery';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import PlaceHolderCard from '../../components/PlaceHolderCard/PlaceHolderCard';
import DataList from '../../components/DataList/DataList';
import UserCard from '../../components/UserCard/UserCard';
import Section from '../../components/Section/Section';
import { VendorViewModel } from '../../core/backend/models';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import HyperLocal from '../../components/HyperLocal/HyperLocal';
import { isMobileDevice } from '../../core/helpers';
import DownloadWidget from '../../components/DownloadWidget/DownloadWidget';
import { observer } from 'mobx-react-lite';
import { IVideoModel } from '../../core/types';
import VideoModel from '../../core/models/VideoModel';
import { useExperienceModal } from '../../components/Modal/ExperienceModal/useExperienceModal';
import { useVideoModal } from '../../components/Modal/VideoModal/useVideoModal';
import GroupedVideoModel from '../../core/models/GroupedVideoModel';
import ExperienceModel from '../../core/models/ExperienceModel';

const EmptyList = <PlaceHolderCard label='Search result will be shown here.' />;

interface ISearchHolder {
  all?: null;
  experiences?: ExperienceModel[];
  events?: ExperienceModel[];
  clips?: IVideoModel[];
  people?: VendorViewModel[];
  hashtags?: { name: string; mediaFiles: IVideoModel[] }[];
}

interface ISearchContent {
  [key: string]: React.ReactNode;
}

const Search = () => {
  const { appDataManager, locationStore } = useStores();
  const { openExperienceModal } = useExperienceModal();
  const { openVideoModal } = useVideoModal();
  const [searchType, setSearchType] = useState<keyof ISearchHolder>('all');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<ISearchHolder>({
    all: null,
    events: [],
    experiences: [],
    clips: [],
    hashtags: [],
    people: []
  });
  const { searchText, debouncedSearchText, onSearchChange } = useSearch(
    '',
    600,
    3
  );
  const [hyperLocalValue, setHyperLocaValue] = useState(
    locationStore.address ?? ''
  );
  const [showHyperLocal, setShowHyperLocal] = useState(!!locationStore.coords);
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    //Track Page
    MixpanelTracking.instance().track(TrackingEvents.SearchPage);
    searchInput.current?.focus();
  }, []);

  useEffect(() => {
    if (!debouncedSearchText.length && !locationStore.coords) {
      setSearchResult(null);
      setIsSearching(false);
      return;
    }

    searchData(debouncedSearchText);
  }, [debouncedSearchText, locationStore.coords]);

  const searchData = async (query: string) => {
    try {
      setIsSearching(true);

      const _query = query.replaceAll('#', '');
      const results = await masterSearch(_query, {
        lat: locationStore.coords?.lat,
        lng: locationStore.coords?.lng
      });

      const hashtags = results.hashtagGroupedResults
        .filter((hash) => hash.groupedMediaFiles.length > 0)
        .map((hashtag) => ({
          name: hashtag.name,
          mediaFiles: hashtag.groupedMediaFiles.map<IVideoModel>(
            (video) => new GroupedVideoModel(video)
          )
        }));

      setSearchResult({
        // experiences: results.experienceResults.map(
        //   (experience) => new ExperienceModel(experience)
        // ),
        events: results.eventResults.map(
          (experience) => new ExperienceModel(experience)
        ),
        clips: results.videoGroupedResults.map(
          (video) => new GroupedVideoModel(video)
        ),
        people: results.providerResults,
        hashtags
      });

      setSearchType('all');

      // //Track Search
      // if (query) {
      //   MixpanelTracking.instance().track(`Search`, {
      //     'Search Criteria': query
      //   });
      // }

      // if (results.eventResults?.length > 0) {
      //   setSearchType('events');
      //   return;
      // }

      // if (results.experienceResults?.length > 0) {
      //   setSearchType('experiences');
      //   return;
      // }

      // if (results.videoResults?.length > 0) {
      //   setSearchType('clips');
      //   return;
      // }

      // if (results.providerResults?.length > 0) {
      //   setSearchType('people');
      //   return;
      // }

      // if (hashtags?.length > 0) {
      //   setSearchType('hashtags');
      //   return;
      // }
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleToggleHyperLocal = () => {
    setShowHyperLocal(!showHyperLocal);
  };

  const handleTabPress = (tabName: keyof ISearchHolder) => () => {
    setSearchType(tabName);
  };

  const searchContent = useMemo<ISearchContent>(
    () => ({
      all: (
        <>
          <Section
            title='Events'
            className='mb-2'
            action={
              searchResult?.events?.length > 12 &&
              (() => setSearchType('events'))
            }
          >
            <GigGallery
              data={searchResult?.events.slice(0, 12)}
              placeholder='There are no event.'
              onPressVideo={(idx) => {
                openExperienceModal({
                  media: searchResult.events,
                  startAtIndex: idx
                });
              }}
            />
          </Section>
          {/* <Section
            title='Experiences'
            className='mb-2'
            action={
              searchResult?.experiences?.length > 12 &&
              (() => {
                setSearchType('experiences');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              })
            }
          >
            <GigGallery
              data={searchResult?.experiences.slice(0, 12)}
              onPressVideo={(idx) => {
                openExperienceModal({
                  media: searchResult.experiences,
                  startAtIndex: idx
                });
              }}
            />
          </Section> */}
          <Section
            title='Clips'
            action={
              searchResult?.clips?.length > 12 &&
              (() => {
                setSearchType('clips');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              })
            }
          >
            <VideoGallery
              data={searchResult?.clips.slice(0, 12)}
              renderItem={(video, idx) => {
                return (
                  <VideoCard
                    key={`video-${video.id}`}
                    video={video}
                    onPress={() => {
                      openVideoModal({
                        media: searchResult.clips,
                        startAtIndex: idx
                      });
                    }}
                  />
                );
              }}
            />
          </Section>
        </>
      ),
      experiences: (
        <GigGallery
          data={searchResult?.experiences}
          onPressVideo={(idx) => {
            openExperienceModal({
              media: searchResult.experiences,
              startAtIndex: idx
            });
          }}
        />
      ),
      events: (
        <GigGallery
          data={searchResult?.events}
          onPressVideo={(idx) => {
            openExperienceModal({
              media: searchResult.events,
              startAtIndex: idx
            });
          }}
        />
      ),
      clips: (
        <VideoGallery
          data={searchResult?.clips}
          renderItem={(video, idx) => {
            return (
              <VideoCard
                key={`video-${video.id}`}
                video={video}
                onPress={() => {
                  openVideoModal({
                    media: searchResult.clips,
                    startAtIndex: idx
                  });
                }}
              />
            );
          }}
        />
      ),
      people: (
        <DataList
          data={searchResult?.people}
          renderItem={(creator, idx) => {
            return (
              <UserCard key={creator.id} user={creator} className='mb-3' />
            );
          }}
        />
      ),
      hashtags: searchResult?.hashtags?.map((hashtag) => (
        <Section
          key={`hashtag-${hashtag.name}`}
          title={`#${hashtag.name}`}
          className='mb-3'
        >
          <VideoGallery
            data={hashtag.mediaFiles}
            renderItem={(video, idx) => {
              return (
                <VideoCard
                  key={`hashtag-video-${video.id}`}
                  video={video}
                  onPress={() => {
                    openVideoModal({
                      media: hashtag.mediaFiles,
                      startAtIndex: idx
                    });
                  }}
                />
              );
            }}
          />
        </Section>
      ))
    }),
    [searchResult]
  );

  return (
    <div className='Search'>
      <Header
        subSection={
          <>
            <div className='Search__inputWrapper'>
              <SearchInput
                className='Search__input'
                ref={searchInput}
                value={searchText}
                onChange={(value) => {
                  onSearchChange(value);
                }}
                onClickHyperLocal={handleToggleHyperLocal}
                isHyperLocalActive={showHyperLocal || !!locationStore.coords}
              />

              {showHyperLocal && (
                <HyperLocal
                  className='Search__hyperLocal'
                  value={hyperLocalValue}
                  onValueChange={(text) => {
                    if (text !== hyperLocalValue) {
                      locationStore.saveLocation(undefined, undefined);
                      appDataManager.updateHyperLocalData(undefined, undefined);
                    }

                    setHyperLocaValue(text);
                  }}
                  onSelectLocation={async ({ fullAddress }, coords) => {
                    setHyperLocaValue(fullAddress);
                    locationStore.saveLocation(fullAddress, coords);
                    appDataManager.updateHyperLocalData(fullAddress, coords);
                  }}
                  onClearSelection={() => {
                    setHyperLocaValue('');
                    locationStore.saveLocation(undefined, undefined);
                    appDataManager.updateHyperLocalData(undefined, undefined);
                  }}
                />
              )}
            </div>

            <div className='Search__tabs mt-3'>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'all'
                })}
                onClick={handleTabPress('all')}
                disabled={isSearching}
              >
                All
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'events'
                })}
                onClick={handleTabPress('events')}
                disabled={isSearching}
              >
                Events
              </button>
              {/* <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'experiences'
                })}
                onClick={handleTabPress('experiences')}
                disabled={isSearching}
              >
                Experiences
              </button> */}
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'clips'
                })}
                onClick={handleTabPress('clips')}
                disabled={isSearching}
              >
                Clips
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'people'
                })}
                onClick={handleTabPress('people')}
                disabled={isSearching}
              >
                Accounts
                {searchResult?.people?.length > 0 &&
                  searchType !== 'people' && (
                    <span className='Search__tabBadge'></span>
                  )}
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'hashtags'
                })}
                onClick={handleTabPress('hashtags')}
                disabled={isSearching}
              >
                Tags
                {searchResult?.hashtags?.length > 0 &&
                  searchType !== 'hashtags' && (
                    <span className='Search__tabBadge'></span>
                  )}
              </button>
            </div>
          </>
        }
      />

      <div className='Search__container mb-5 px-3 px-lg-4'>
        <div className='Search__content'>
          {!searchResult && !isSearching ? (
            EmptyList
          ) : searchResult && searchResult[searchType]?.length === 0 ? (
            <PlaceHolderCard label='We searched far and wide. Unfortunately, no results here.' />
          ) : isSearching ? (
            <Loader fixed={false} />
          ) : (
            searchContent[searchType]
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Search);
