import React from 'react';
import './VideoGallery.scss';
import { IVideoModel } from '../../core/types';

interface IProps {
  data: IVideoModel[];
  itemCount?: number;
  renderItem: (video: IVideoModel, index: number) => React.ReactNode;
  placeholder?: React.ReactNode;
  profile?: boolean;
}

const VideoGallery = ({
  data,
  itemCount,
  renderItem,
  placeholder,
  profile
}: IProps) => {
  const items = itemCount ? data.slice(0, itemCount) : data;
  return (
    <div className='VideoGallery container'>
      {!items || items?.length === 0 ? (
        placeholder ?? (
          <div className='VideoGallery__noClips'> There are no clips.</div>
        )
      ) : (
        <div
          className={`row row-cols-3 row-cols-sm-4 row-cols-md-5 row-cols-lg-6 row-cols-xl-8 g-2 ${
            profile && 'VideoGallery--profile'
          }`}
        >
          {items.slice(0, itemCount).map((video, index) => {
            return renderItem(video, index);
          })}
        </div>
      )}
    </div>
  );
};

export default VideoGallery;
