import { useState, useEffect, KeyboardEvent, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { observer } from 'mobx-react-lite';
import { getOwnLikes, getVideoComments, likeVideo } from '../../../core/api';
import { TrackingEvents } from '../../../core/enums';
import {
  generatePrivateMessage,
  removeMentionsInText
} from '../../../core/helpers';
import MixpanelTracking from '../../../core/services/MixpanelTracking';
import { IVideoModel, MediaFile } from '../../../core/types';
import VideoDetails from '../../Details/VideoDetails/VideoDetails';
import Loader from '../../Loader/Loader';
import SEO from '../../SEO/SEO';
import Modal from '../Modal';
import { ReactComponent as DownArrow } from '../../../icons/downarrow.svg';
import './VideoModal.scss';
import Button from '../../Button/Button';
import CommentsModal from '../CommentsModal/CommentsModal';
import VideoActions from '../../VideoActions/VideoActions';
import { useStores } from '../../../hooks';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/validators';
import appToast from '../../../core/toast';
import { useAuthModal } from '../AuthModal/AuthModal';
import VideoDescription from '../../VideoDescription/VideoDescription';
import MediaPlayer from '../../MediaPlayer/MediaPlayer';
import { useShareModal } from '../ShareContentModal/useShareModal';
import { useVideoModal } from './useVideoModal';
import { useExperienceModal } from '../ExperienceModal/useExperienceModal';
import { COMMENTS_PER_PAGE } from '../../../core/consts';
import ReportMediaModal from '../ReportMediaModal/ReportMediaModal';
import GoogleTagManager from '../../../core/services/GoogleTagManager';
import TikTokPixel from '../../../core/services/TikTokPixel';
import MetaPixel from '../../../core/services/MetaPixel';

const VideoModal = () => {
  const { showAuthModal } = useAuthModal();
  const { showShareModal } = useShareModal();
  const {
    media,
    startAtIndex,
    showMediaControls,
    isOpen,
    closeVideoModal,
    mediaState,
    updateVideoModalProps
  } = useVideoModal();
  const experienceModal = useExperienceModal();
  const { likedMedia, userStore, commentStore } = useStores();
  const [selectedClip, setSelectedClip] = useState<IVideoModel>(null);
  const [mediaGroupId, setMediaGroupId] = useState<number>(null);
  const [currentMediaFile, setCurrentMediaFile] = useState<MediaFile>(null);
  const [currentClipIndex, setCurrentClipIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLikingClip, setIsLikingClip] = useState(false);
  const [isShowingComments, setIsShowingComments] = useState(false);
  const viewTimerRef = useRef<NodeJS.Timeout>(null);
  const [reportModalOpen, setReportModalOpen] = useState(false);

  useEffect(() => {
    updateVideoModalProps({
      mediaState: experienceModal.isOpen ? 'paused' : 'playing'
    });
  }, [experienceModal.isOpen]);

  useEffect(() => {
    if (!media?.length) return;

    setCurrentClipIndex(startAtIndex);
  }, [startAtIndex, media]);

  useEffect(() => {
    if (!isOpen || currentClipIndex < 0 || media.length === 0) return;

    const currentClip = media[currentClipIndex];

    if (!currentClip) return;

    setSelectedClip(currentClip);
    setIsLoading(false);

    GoogleTagManager.dataLayer({
      event: TrackingEvents.WatchClip,
      userType: userStore.user ? 'Authenticated' : 'Guest'
    });

    MetaPixel.track('ViewContent', {
      content_name: currentClip?.description ?? '',
      content_type: 'product',
      content_ids: [currentClip?.id.toString()],
      content_category: 'Clip',
      value: 0
    });

    TikTokPixel.track('ViewContent', {
      content_id: currentClip?.id.toString(),
      content_name: currentClip?.description ?? ''
    });
  }, [isOpen, media, currentClipIndex, isLoading]);

  const getMediaComments = async (mediaFileId: number) => {
    try {
      const commentsResponse = await getVideoComments(
        mediaFileId,
        0,
        COMMENTS_PER_PAGE
      );
      const sortedComments = commentsResponse.sort(
        (a, b) => a.timeStamp - b.timeStamp
      );

      commentStore.saveToStore(sortedComments);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    }
  };

  const handlePrevVideo = () => {
    if (currentClipIndex > 0) {
      setCurrentClipIndex(currentClipIndex - 1);
      clearInterval(viewTimerRef.current);
      setMediaGroupId(null);
      viewTimerRef.current = null;
    }
  };

  const handleNextVideo = () => {
    if (currentClipIndex < media.length - 1) {
      setCurrentClipIndex(currentClipIndex + 1);
      clearInterval(viewTimerRef.current);
      setMediaGroupId(null);
      viewTimerRef.current = null;
    }
  };

  const handleLikeMedia = (mediaId: number) => async () => {
    if (!userStore.user) {
      showAuthModal();
      return;
    }

    const isLiked = likedMedia.isMediaLiked(mediaId);

    try {
      setIsLikingClip(true);

      const mixpanelPayload = {
        'Moment ID': mediaId,
        'Moment Description': selectedClip.description ?? '',
        Category: selectedClip.linkedExperience?.category?.name ?? 'None'
      };

      MixpanelTracking.instance().track(
        isLiked ? TrackingEvents.UnlikeMoment : TrackingEvents.LikeMoment,
        mixpanelPayload
      );

      await likeVideo(mediaId);

      //TODO: Ask BE devs to return LikeViewModel in the previous request
      const userLikes = await getOwnLikes();
      likedMedia.saveToStore(userLikes);

      let currentLikeCount = selectedClip.likes;
      currentLikeCount += isLiked ? -1 : 1;

      if (currentLikeCount <= 0) currentLikeCount = 0;

      selectedClip.likes = currentLikeCount;
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
      likedMedia.remove(mediaId);

      selectedClip.likes += isLiked ? -1 : 1;
    } finally {
      setIsLikingClip(false);
    }
  };

  const removeMediaFileFromClip = (mediaFileId: number) => {
    selectedClip.mediaFiles = selectedClip.mediaFiles.filter(
      (mediaFile) => mediaFile.id !== mediaFileId
    );
  };

  const handleShowComments = () => {
    if (!userStore.user) {
      showAuthModal();
      return;
    }

    setIsShowingComments(true);
  };

  const handleCloseComments = () => {
    setIsShowingComments(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp') {
      handlePrevVideo();
    }

    if (e.key === 'ArrowDown') {
      handleNextVideo();
    }
  };

  const handleShowShareModal = (clipId: number) => () => {
    clipId && showShareModal({ contentId: clipId, contentType: 'clip' });
  };

  const handleShowReportMediaModal = () => {
    setReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
  };

  const handleResetModal = () => {
    setMediaGroupId(null);
    clearInterval(viewTimerRef.current);
    viewTimerRef.current = null;
  };

  const handleOnClose = () => {
    handleResetModal();
    closeVideoModal();
  };

  return isOpen && isLoading ? (
    <Loader />
  ) : selectedClip ? (
    <>
      <SEO
        title={removeMentionsInText(
          selectedClip.description ??
            selectedClip.linkedExperience?.name ??
            'I thought you might find this clip interesting'
        )}
        description='Watch this and other awesomen clips on hoopla.com'
        url={`/clip/${selectedClip.id}`}
        image={selectedClip.thumbnails?.static}
        video={selectedClip.thumbnails?.video}
      />

      <Modal
        isOpened={isOpen}
        close={closeVideoModal ? handleOnClose : null}
        onKeyDown={handleKeyDown}
        closeButtonClassName='VideoModal__closeButton'
      >
        <div className='VideoModal'>
          <div className='VideoModal__content'>
            <MediaPlayer
              autoPlayVideos={mediaState === 'playing'}
              forceToStopMedia={reportModalOpen}
              files={selectedClip.mediaFiles}
              thumbnail={selectedClip.thumbnails}
              embed={
                <VideoDescription
                  video={selectedClip}
                  onViewExperience={
                    selectedClip.linkedExperience
                      ? () => {
                          if (
                            selectedClip.linkedExperience?.isPrivate &&
                            userStore?.user?.id !==
                              selectedClip.linkedExperience?.creator?.id
                          ) {
                            return appToast.showError(
                              generatePrivateMessage(
                                selectedClip.linkedExperience?.isOneTimeEvent
                              )
                            );
                          }

                          /**
                           * This fixes the overlap issue when experience modal is open
                           * and the the video modal is open later and user tries to
                           * open an experience from suggestions. Close video modal.
                           */
                          if (experienceModal.isOpen) {
                            handleOnClose();
                          }

                          experienceModal.openExperienceModal({
                            media: [selectedClip.linkedExperience],
                            startAtIndex: 0
                          });
                        }
                      : null
                  }
                />
              }
              onChangeMediaFile={(selectedFile) => {
                setCurrentMediaFile(selectedFile);

                if (mediaGroupId) return;

                setMediaGroupId(selectedFile.id); //Set first ID as group id
                userStore.user && getMediaComments(selectedFile.id); //Load comments for group ID just once
              }}
              afterViewIsAdded={() => {
                selectedClip.views += 1;
              }}
            />

            <VideoActions
              className='VideoModal__actions'
              isLiked={likedMedia.isMediaLiked(mediaGroupId ?? 0)}
              isLiking={isLikingClip}
              likesCount={selectedClip.likes ?? 0}
              commentsCount={commentStore.comments.length}
              onLikeVideo={handleLikeMedia(mediaGroupId ?? 0)}
              onShowComments={handleShowComments}
              onShareContent={handleShowShareModal(mediaGroupId ?? 0)}
              onReportMediaFile={handleShowReportMediaModal}
            />

            {showMediaControls && (
              <div className='VideoModal__mediaControls'>
                <Button
                  className='VideoModal__actionButton VideoModal__upButton'
                  variant='icon'
                  icon={<DownArrow className='VideoModal__actionButton--up' />}
                  onClick={handlePrevVideo}
                  disabled={!(currentClipIndex > 0)}
                />

                <Button
                  className='VideoModal__actionButton'
                  variant='icon'
                  icon={<DownArrow />}
                  onClick={handleNextVideo}
                  disabled={!(currentClipIndex < media.length - 1)}
                />
              </div>
            )}
          </div>

          <div className='VideoModal__sidebar'>
            <div className='VideoModal__content-wrapper'>
              <Scrollbars
                autoHeight
                renderTrackHorizontal={(props) => <div></div>}
                autoHeightMin={100}
                autoHeightMax='100%'
              >
                <VideoDetails
                  onClick={(idx, experiences) => {
                    const selectedEvent = experiences[idx];
                    if (
                      selectedEvent?.isPrivate &&
                      userStore?.user?.id !== selectedEvent?.creator?.id
                    ) {
                      return appToast.showError(
                        generatePrivateMessage(selectedEvent?.isOneTimeEvent)
                      );
                    }

                    /**
                     * This fixes the overlap issue when experience modal is open
                     * and the the video modal is open later and user tries to
                     * open an experience from suggestions. Close video modal.
                     */
                    if (experienceModal.isOpen) {
                      handleOnClose();
                    }

                    experienceModal.openExperienceModal({
                      media: experiences,
                      startAtIndex: idx
                    });
                  }}
                />
              </Scrollbars>
            </div>
          </div>
        </div>
      </Modal>

      {mediaGroupId && (
        <CommentsModal
          isOpened={isShowingComments}
          mediaFileId={mediaGroupId}
          close={handleCloseComments}
        />
      )}

      <ReportMediaModal
        isOpened={reportModalOpen}
        mediaFileId={currentMediaFile?.id}
        afterReporting={removeMediaFileFromClip}
        close={handleCloseReportModal}
      />
    </>
  ) : null;
};

export default observer(VideoModal);
