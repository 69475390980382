import Button from '../Button/Button';
import { ReactComponent as LikeIcon } from '../../icons/likebutton.svg';
import { ReactComponent as LikedIcon } from '../../icons/likedbutton.svg';
import { ReactComponent as CommentsIcon } from '../../icons/commentsbutton.svg';
import { ReactComponent as ShareIcon } from '../../icons/sharebutton.svg';
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';
import { ReactComponent as ReportIcon } from '../../icons/report.svg';
import './VideoActions.scss';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { ListItemProps } from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';

interface Props {
  className?: string;
  isLiked: boolean;
  isLiking: boolean;
  likesCount: number;
  commentsCount: number;
  onLikeVideo: () => void;
  onShowComments: () => void;
  onShareContent: () => void;
  onReportMediaFile: () => void;
}

const VideoActions = ({
  className = '',
  isLiked,
  isLiking,
  likesCount,
  commentsCount,
  onLikeVideo,
  onShareContent,
  onShowComments,
  onReportMediaFile
}: Props) => {
  const options: ListItemProps[] = [
    {
      name: `Report Media`,
      callback: onReportMediaFile,
      icon: <ReportIcon />
    }
  ];

  return (
    <div className={`VideoActions__actions ${className}`}>
      <div className='VideoActions__actionButtonWrapper'>
        <Button
          className='VideoActions__actionButton'
          variant='icon'
          icon={
            isLiking ? (
              <Loader width='20px' fixed={false} showLogo={false} light />
            ) : isLiked ? (
              <LikedIcon />
            ) : (
              <LikeIcon />
            )
          }
          onClick={onLikeVideo}
          disabled={isLiking}
        />
        <span className='VideoActions__actionButtonCounter'>{likesCount}</span>
      </div>

      <div className='VideoActions__actionButtonWrapper'>
        <Button
          className='VideoActions__actionButton'
          variant='icon'
          icon={<CommentsIcon />}
          onClick={onShowComments}
        />
        <span className='VideoActions__actionButtonCounter'>
          {commentsCount}
        </span>
      </div>

      <div className='VideoActions__actionButtonWrapper'>
        <Button
          className='VideoActions__actionButton'
          variant='icon'
          icon={<ShareIcon />}
          onClick={onShareContent}
        />
      </div>

      <DropdownMenu list={options} align='left'>
        <div className='VideoActions__actionButtonWrapper'>
          <Button
            className='VideoActions__actionButton'
            variant='icon'
            icon={<DotsIcon />}
          />
        </div>
      </DropdownMenu>
    </div>
  );
};

export default VideoActions;
